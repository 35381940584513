@font-face {
  font-family: 'Orbitron';
  src: url('Orbitron-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Gantari';
  src: url('Gantari-VariableFont_wght.ttf');
}

#app {
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100vw;
  height: 100vh;
}

#app img {
  display: block;
  max-height: 100vh;
  max-width: 60vw;
}

#app .content {
  height: max-content;
  color: #fff;
  transform: translate(20%, -100%);
  position: relative;
}

#app .content h3 {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-family: 'Orbitron', sans-serif;
}

#app .content h1 {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: 'Gantari', sans-serif;
}

#app .content .formContainer { margin-block-start: 30px; }

#app .content .formContainer .sended {
  font-family: 'Orbitron', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  color: #75AADB;
}

#app .content .formContainer form {
  display: flex;
  flex-direction: column;
  font-family: 'Orbitron', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .4px;
}

#app .content .formContainer form .inputGroup {
  display: flex;
  margin-block-start: 10px;
}

#app .content .formContainer form .inputGroup input,
#app .content .formContainer form .inputGroup button {
  height: 35px;
  box-sizing: border-box;
  border-radius: 0;
  box-shadow: unset;
  font-family: 'Gantari', sans-serif;
  text-transform: uppercase;
  letter-spacing: .5px;
}
#app .content .formContainer form .inputGroup input:focus,
#app .content .formContainer form .inputGroup input:focus::placeholder,
#app .content .formContainer form .inputGroup button:focus {
  outline: none;
  background: #75AADB;
  color: #fff;
}
#app .content .formContainer form .inputGroup button:hover,
#app .content .formContainer form .inputGroup button:focus {
  background: #75AADB;
  color: #fff;
  cursor: pointer;
}

#app .content .formContainer form .inputGroup input {
  width: 100%;
  padding-inline: 10px;
  background: transparent;
  border: 1px solid #fff;
  border-right: 0;
}

#app .content .formContainer form .inputGroup input::placeholder {
  text-transform: uppercase;
  font-style: italic;

}
#app .content .formContainer form .inputGroup button {
  background: #fff;
  border: 1px solid #fff;
  border-left: 0;
  padding-inline: 20px;
}

@media screen and (max-width: 1024px) {
  #app {
    flex-direction: column;
    align-items: flex-start;
  }

  #app img {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 100vw;
  }

  #app .content {
    position: initial;
    transform: unset;
    z-index: 2;
    padding: 20px;
    width: 100%;
    max-width: 100vw;
    box-sizing: border-box;
    background: rgba(0 0 0 / .3);
    backdrop-filter: blur(2px);
  }

  #app .content .formContainer { max-width: max-content; }
}

@media screen and (max-width: 460px) {
  #app .content h1 {
    font-size: 28px;
  }
  #app .content h3 {
    font-size: 16px;
  }
}